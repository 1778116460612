/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    ul: "ul",
    li: "li",
    h2: "h2",
    ol: "ol",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, VideoIframe, MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aids/how-to-connect-hearing-aids-to-smartphone/'], ['en', 'https://www.hear.com/resources/hearing-aids/how-to-connect-hearing-aids-to-smartphone/'], ['en-US', 'https://www.hear.com/resources/hearing-aids/how-to-connect-hearing-aids-to-smartphone/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aids/how-to-connect-hearing-aids-to-smartphone/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-pair-hearing-aids-to-phone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-pair-hearing-aids-to-phone",
    "aria-label": "how to pair hearing aids to phone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Pair Hearing Aids to Phone"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/man-with-smartphone.png",
    alt: "man connected hearing aids trough Smartphone",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many of today’s ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " can be paired with your iPhone or Android mobile phone trough ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connectivity"), ". This allows you to control the volume, adjust your sound preferences, and select specific hearing programs for different environments. In addition to giving you full control over the way you hear, many smartphone compatible hearing aids can do the following…"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Stream calls and listen to music directly from your phone"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Keep a hearing diary, check battery life and get remote support"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Use cutting-edge features like language translation, health monitoring, and fall detection"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Connecting to your hearing aids depends on the model of your hearing aids and your smartphone. For your convenience, we’ve outlined the steps you need to take to connect your hearing aids to your iPhone or Android mobile phone."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "download-the-smartphone-app",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#download-the-smartphone-app",
    "aria-label": "download the smartphone app permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Download the smartphone app"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Each manufacturer has its own app that you can download for free from the App Store or Google Play. Here are the links to the apps from the eight major manufacturers we work with at hear.com."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "hear.com Horizon: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/hear-com-horizon/id1489152822",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.heardotcom.rta&hl=en_US&gl=US",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Signia: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/signia-app/id1316911473",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.signia.rta&hl=en&gl=US",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Starkey: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/thrive-hearing-control/id1322659659?ls=1",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.starkey.android.newlink.release",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sony | Hearing Control: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/sony-hearing-control/id1633950748",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=jp.co.sony.nb.hearingcontrol&hl=en_US",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Oticon: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/oticon-companion/id1597064213",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.oticon.app",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "ReSound: ", React.createElement("a", {
    href: "https://apps.apple.com/app/resound-smart/id816203976",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.resound.smart",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Phonak: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/phonak-remote/id1332662924",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.sonova.phonak.dsapp",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Widex: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/widex-evoke/id1305050279",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.widex.dua",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Rexton: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/rexton-app/id1356550309",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.connexx.rta&hl=en&gl=US",
    target: "_blank"
  }, "Google Play (Android)")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Unitron: ", React.createElement("a", {
    href: "https://apps.apple.com/us/app/unitron-remote-plus/id1256680977?ls=1",
    target: "_blank"
  }, "App Store (iPhone)"), " | ", React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=com.sonova.unitron.rcapp&hl=en",
    target: "_blank"
  }, "Google Play (Android)")), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "connecting-to-your-iphone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connecting-to-your-iphone",
    "aria-label": "connecting to your iphone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Connecting to your iPhone"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure your Bluetooth is on by going to ", React.createElement(_components.strong, null, "Settings > Bluetooth"), "."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Put the hearing aids in the charger for approx. 20 seconds."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Open ", React.createElement(_components.strong, null, "Settings"), " on your phone."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Go to ", React.createElement(_components.strong, null, "Accessibility"), "."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tap on ", React.createElement(_components.strong, null, "Hearing Devices"), "."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Select your hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Take the hearing aids out of the charger."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Then on your phone tap connect or ", React.createElement(_components.strong, null, "Pair"), "."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here’s a short video that shows exactly how to connect your hear.com hearing aids to your iPhone. For the other hearing aid manufacturers, the specific software will be different, but the general process is similar. You can also visit the Apple website to check if your hearing aids are compatible with your iPhone and read more detailed instructions on connecting your devices."), "\n", React.createElement(VideoIframe, {
    title: "How to pair your hear.com hearing aids to your iPhone",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/NhN40ZBarpU?si=DjlMwSEfIjITq57S"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For the other hearing aid manufacturers, the specific software will be different, but the general process is similar."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "connecting-to-your-android-mobile-phone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connecting-to-your-android-mobile-phone",
    "aria-label": "connecting to your android mobile phone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Connecting to your Android mobile phone"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s important to note that not all hearing aid models are compatible with Android devices. Check the user manual, manufacturer’s website, or ask your hear.com hearing consultant to ensure your Android works with your hearing aids."), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Turn on your hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Open ", React.createElement(_components.strong, null, "Google Play"), " and download the app for your specific hearing aid if one is available. The app might be able to walk you through the process of pairing your devices. Otherwise, continue with step 3."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Go to ", React.createElement(_components.strong, null, "Settings"), " on your phone."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tap ", React.createElement(_components.strong, null, "Connected devices"), " > ", React.createElement(_components.strong, null, "Pair new device"), "."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Choose your hearing aid from the list of available devices. If you have more than one hearing aid: Wait for the first hearing aid to connect, then tap the other hearing aid in the list of available devices."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This video demonstrates how to connect your hear.com hearing aids to your Android device. For the other hearing aid manufacturers, the specific software will be different, but the general process is similar."), "\n", React.createElement(VideoIframe, {
    title: "How to pair your hear.com hearing aids with Android",
    width: "500",
    height: "281",
    src: "https://www.youtube.com/embed/92rCUU0UY9c?si=w7lhm6QkpWT_tuyO"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you need additional help connecting your hearing aids to your phone, please give us a call at (786) 520-2456 so we can assist you."), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Get Assistance from a Specialist",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
